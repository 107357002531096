import React from 'react';
import {
  Box,
  Flex,
  Button,
  Heading,
  Container,
  UnorderedList,
  ListItem,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { graphql } from 'gatsby';
import shortid from 'shortid';
import { Layout, ScrollAnimation, LinkOne } from '../components/index';

const makeId = () => {
  return shortid.generate();
};

const services = [
  {
    name: 'Health System and hospitals',
    services: [
      'Managed care financial analysis and modeling',
      'Managed care contract assessments',
      'Health plan and provider contract negotiations',
      'Interim management support/heath plan negotiation',
      'Staffing plans and recommendations',
      'Market assessments and analysis',
    ],
  },
  {
    name: 'Medical Groups and IPAs',
    services: [
      'Health Plan Contract Negotiations',
      'Physician Recruitment Efforts',
      'Medical Group and IPA Development',
      'Managed Care Marketing Analysis',
      'Program Development and Service Line Opportunities',
    ],
  },
  {
    name: 'Other services',
    services: [
      'Hospital/Physician Joint Venture Programs and Services (e.g. ASC, MRI, cardiac surgery, rehabilitation and other specialized programs)',
      'Business Plan Development and Financial Projections',
      'Volume and Program Planning',
      'Hospitalist Program Development',
    ],
  },
];

const clientTools = [
  {
    name: 'Financial Modeling',
    description:
      'CCG is a market leader when it comes to financial analysis and modeling. We have secured millions of dollars in contract increases as a result of our financial modeling and market knowledge. We will prepare a financial impact analysis of your top contracts to identify pricing strategies to maximize reimbursement. We can prepare a customized model at the service line level to analyze the impact of the current and proposed rates during negotiations with a health plan. This strategic tool allows the client to model a number of “what if” scenarios to determine the best pricing strategy for the hospital or medical group.',
  },
  {
    name: 'Managed Care Database',
    description:
      'Our proprietary Access Database allows us to load all of your health plan contracts into our database. The database is currently used by health systems and medical groups throughout the country to manage their portfolio of contracts. This customized database provides the clients with a series of reports to effectively manage their contract portfolio. Provided that CCG is given sufficient electronic data and supporting information, the following reports can be generated from the database:',
  },
  {
    name: 'Best Practices Contract Language Database',
    description:
      'CCG maintains a “Best Practices” contract language database. The database is the result of 30 years of experience in negotiating health plan agreements for health systems, hospitals and medical groups. Using the database, we provide “Best Practice Language” recommendations to our clients. This database is updated on a quarterly basis and has been very effective in contract negotiations. We will review your contracts to determine potential financial concerns, contract business issues, and general contract language provisions.',
  },
];

const servicesPage = ({ data }) => {
  return (
    <Layout>
      <Box bg="brand.dark" color="white" py={20}>
        <Container maxW="container.xl">
          <Box p={6} minH="400px">
            <Heading as="h1" size="3xl" fontWeight="light" mb={4} mt={8}>
              Services
            </Heading>
            <Grid templateRows="repeat(2, 1fr)" gap={6}>
              {services.map((s, i) => (
                <GridItem
                  maxWidth={{ base: '650px' }}
                  colSpan={{ base: '2', md: '1' }}
                  key={makeId()}
                >
                  <ScrollAnimation>
                    <Heading size="xl" fontWeight="light" mb={6} mt={10}>
                      {s.name}
                    </Heading>
                    <UnorderedList>
                      {s.services.map((ser) => (
                        <ListItem key={makeId()}>
                          <Text fontWeight="light" size="xl">
                            {ser}
                          </Text>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </ScrollAnimation>
                </GridItem>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box bg="brand.one" py={20}>
        <Container maxW="container.xl">
          <Box p={6} minH="400px">
            <Heading as="h1" size="3xl" fontWeight="light" mb={4} mt={8}>
              Client Tools
            </Heading>
            <Grid templateRows="repeat(2, 1fr)" gap={6}>
              {clientTools.map((s, i) => (
                <GridItem
                  maxWidth={{ base: '650px' }}
                  colSpan={{ base: '2', md: '1' }}
                  key={makeId()}
                >
                  <ScrollAnimation>
                    <Heading size="xl" fontWeight="light" mb={6} mt={10}>
                      {s.name}
                    </Heading>

                    <Text>{s.description}</Text>
                  </ScrollAnimation>
                </GridItem>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query {
    medicalProfessional: file(
      relativePath: { eq: "happy-medical-professional.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default servicesPage;
